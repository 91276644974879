// LearnMoreButton.tsx
import React from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LearnMoreBtn = styled.a`
  background-color: transparent;
  border: none;
  color: #000;
  padding: 10px 20px;

  font-family: 'Avenir';
font-style: normal;
font-weight: 800;
font-size: 12.4906px;
line-height: 27px;
text-align: center;
letter-spacing: 3.71523px;

color: #FFFFFF;

text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
text-decoration: none;
`;

const LearnMoreButton = () => {
  return (
    <ButtonContainer>
      <LearnMoreBtn href="https://dopegameshow.notion.site/What-s-Dope-For-Teams-4ea98b11ea69457ca244a04308b79e3a">LEARN MORE</LearnMoreBtn>
    </ButtonContainer>
  );
};

export default LearnMoreButton;
