// Footer.tsx
import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
//   background-color: #black; // You can change this color to match your theme
`;

const CopyrightText = styled.p`
  font-family: 'Mona-Sans Medium Wide';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer>
      <CopyrightText>
        &copy; {currentYear} 9978. All Rights Reserved.
      </CopyrightText>
    </FooterContainer>
  );
};

export default Footer;
