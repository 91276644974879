// HookDescription.tsx
import React from 'react';
import styled from 'styled-components';

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: pre-wrap;

  font-family: 'Mona-Sans Wide';
font-style: normal;
font-weight: 600;
font-size: 22.8738px;
line-height: 124.7%;
/* or 29px */

text-align: center;
  width: 350px;
color: #FFFFFF;

text-shadow: 0px 0px 12.1097px rgba(0, 0, 0, 0.624292);

`;

const HookDescription = () => {
  return (
    <DescriptionContainer>
    Save time & effort. {'\n'}
Boost team engagement with virtual video contests.
    </DescriptionContainer>
  );
};

export default HookDescription;
