// Logo.tsx
import React from 'react';
import styled from 'styled-components';
// import logoImage from '/Dope.svg'; // Replace 'your-image-name' with the actual image file name

const LogoImage = styled.img`
  width: 90px;
  height: 29px;
  margin: 20px 0;
`;

const Logo = () => {
  return (
    <LogoImage
      src="Dope.svg"
      alt="dope logo"
    />
  );
};

export default Logo;
