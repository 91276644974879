// CallToActionButton.tsx
import React from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CallToActionBtn = styled.button`
  box-sizing: border-box;
  background: #FF1654;
  border: 2px solid rgba(255, 255, 255, 0.31);
  border-radius: 15px;
  width: 250px;
  height: 50px;

  font-family: 'Avenir';
font-style: normal;
font-weight: 800;
font-size: 15px;
line-height: 34px;
text-align: center;
letter-spacing: 1px;
color: white;
`;

const CallToActionButton = () => {
  return (
    <ButtonContainer>
      <CallToActionBtn data-tally-open="m6LY4O" data-tally-width="500" data-tally-layout="modal" data-tally-emoji-text="👋" data-tally-emoji-animation="wave" data-tally-auto-close="2000">BOOK A DEMO</CallToActionBtn>
    </ButtonContainer>
  );
};

export default CallToActionButton;
