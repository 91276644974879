// FivePointsComponent.tsx
import React from 'react';
import styled from 'styled-components';


const PointsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: 'Mona-Sans Medium Wide';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  /* or 122% */

  text-align: center;
`;

const PointsRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 800px; // Add max-width to limit the row width
  margin-left: auto; // Center the row horizontally
  margin-right: auto; // Center the row horizontally
`;

const Point = styled.div`
  margin: 40px;

  white-space: pre-wrap;
  text-align: center;
`;

const FivePointsComponent = () => {
  const points = [
    { id: 1, text: '🌟 Reinforce company\nvalues and culture' },
    { id: 2, text: '🏆 Put contests &\ngiveaways on autopilot' },
    { id: 4, text: '💪 Empower your team\nto be creative' },
    { id: 3, text: '😄 Boost team morale\nwith friendly competitions ' },
  ];

  return (
    <PointsContainer>
      <PointsRow>
        {points.map((point) => (
          <Point key={point.id}>{point.text}</Point>
        ))}
      </PointsRow>
    </PointsContainer>
  );
};

export default FivePointsComponent;
