// MainHookTitle.tsx
import React from 'react';
import styled from 'styled-components';

const Title = styled.h1`
  font-family: 'Mona-Sans Wide', sans-serif;
  font-size: 50px;
  text-align: center;
  margin: 20px 0;
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  letter-spacing: -1.0125px;
  line-height: 59px;
  max-width: 593.33px;

  span {
    background-image: linear-gradient(101.28deg, #FF1654 33.17%, #BB5EFC 88.68%); /* Define the gradient colors */
    -webkit-background-clip: text; /* Clip the background to the text */
    color: transparent; /* Make the text color transparent to reveal the gradient */
  }

  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;

`;

const MainHookTitle = () => {
  return (
    <Title>
      <span>Elevate</span> your team with virtual contests
      {/* Unleash the power of<br /><span>virtual contests</span> */}
    </Title>
  );
};

export default MainHookTitle;
