// LandingPage.tsx
import React from 'react';
import styled from 'styled-components';
import Logo from './Logo';
import MainHookTitle from './MainHookTitle';
import FivePointsComponent from './FivePointsComponent';
import MockupPicture from './MockupPicture';
import MiniHook from './MiniHook';
import HookDescription from './HookDescription';
import {ScreenBlock,PrizeScreenBlock} from './ScreenBlock';
// import PrizeScreenBlock from './ScreenBlock';
import CallToActionButton from './CallToActionButton';
import LearnMoreButton from './LearnMoreButton';
import contestImage from '../assets/Contest.png';
import watchImage from '../assets/Watch.png';
import prizeImage from '../assets/Prize.png';


const LandingPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
`;

const StackItem = styled.div`
  margin-top: 15px; /* Adjust this value to control the spacing between elements */
`;

const StackPictureItem = styled.div`
  margin-top: 50px; /* Adjust this value to control the spacing between elements */
`;

const MiniHookItem  = styled.div`
  margin-top: 40px; /* Adjust this value to control the spacing between elements */
`;

const LandingPage = () => {
  return (
    <LandingPageContainer>
      <StackItem>
        <Logo />
      </StackItem>
      <StackItem>
        <MainHookTitle />
      </StackItem>
      <StackItem>
        <FivePointsComponent />
      </StackItem>

    <StackItem>
    <ScreenBlock
        title="Set it and forget it"
        description="We make fun contests with cash prizes for your team and 
        release them weekly!"
        imageUrl={contestImage}
      />
    </StackItem>

    <StackItem>
    <ScreenBlock
        title="Watch and react!"
        description="React to your co-worker’s submissions with emojis to decide who wins!"
        imageUrl={watchImage}
      />
    </StackItem>

    <StackItem>
    <PrizeScreenBlock
        title="Win cash prizes & 
        redeem gift cards!"
        description="Submissions with the most emoji reactions win cash prizes to redeem gift cards!"
        imageUrl={prizeImage}
      />
    </StackItem>


     
      <MiniHookItem>
        <MiniHook />
      </MiniHookItem>
      <MiniHookItem>
        <HookDescription />
      </MiniHookItem> 
      <MiniHookItem>
        <CallToActionButton />
      </MiniHookItem>
      <StackItem>
        <LearnMoreButton />
      </StackItem>
    </LandingPageContainer>
  );
};


export default LandingPage;
