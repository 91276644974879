// ScreenBlock.tsx
import React from 'react';
import styled from 'styled-components';

interface ScreenBlockProps {
    title: string;
    description: string;
    imageUrl: string;
}

const ScreenBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 30px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  text-align: center;
  width: 300px;
`;

const Description = styled.p`
  font-size: 18px;
  text-align: center;
  width: 250px;
  line-height: 25px;
  height: 70px;
  margin-top: -7px;
  margin-bottom: 35px;
`;

const Image = styled.img`
  width: 100%;
  max-width: 331px; // Set a max-width to limit the image size
  height: auto;
`;

const PrizeImage = styled.img`
  width: 100%;
  max-width: 400px; // Set a max-width to limit the image size
  height: auto;
`;

export const ScreenBlock: React.FC<ScreenBlockProps> = ({ title, description, imageUrl }) => {
    return (
        <ScreenBlockContainer>
          <Title>{ title } </Title>
          <Description> { description } </Description>
          <Image src = { imageUrl } alt = { title } />
        </ScreenBlockContainer>
  );
};

export const PrizeScreenBlock: React.FC<ScreenBlockProps> = ({ title, description, imageUrl }) => {
  return (
      <ScreenBlockContainer>
        <Title>{ title } </Title>
        <Description> { description } </Description>
        <PrizeImage src = { imageUrl } alt = { title } />
      </ScreenBlockContainer>
);
};

// export default ScreenBlock;
// export PrizeScreenBlock;
