// MiniHook.tsx
import React from 'react';
import styled from 'styled-components';

const MiniHookContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: pre-wrap;

  font-family: 'Mona-Sans Wide';
  font-style: normal;
  font-weight: 400;
  font-size: 22.8738px;
  line-height: 29px;
  text-align: center;
  
  color: #FFFFFF;
  
  text-shadow: 0px 0px 12.1097px rgba(0, 0, 0, 0.624292);
`;

const MiniHook = () => {
  return (
    <MiniHookContainer>
    The best type of team activity{'\n'}doesn't feel like work.
    </MiniHookContainer>
  );
};

export default MiniHook;
