// AppContainer.tsx
import React from 'react';
import styled from 'styled-components';
import App from './App';

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  color: white;
  font-family: 'Mona-Sans Wide', sans-serif;
`;

const AppContainer = () => {
  return (
    <Container>
      <App />
    </Container>
  );
};

export default AppContainer;
